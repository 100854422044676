import { Api } from "@evergis/api";

import { APP_CONFIG, ROUTES } from "../appConfig";
import { stripUselessSlashes } from "../utils/string";

const locationUrl = APP_CONFIG.API_URL ? new URL(APP_CONFIG.API_URL) : window.location;

const LOGOUT_STATUS_CODES = [401];
const PRIVATE_ROUTES: string[] = [ROUTES.PROFILE];
const API_URL_PARTS = [process.env.API_PROXY_PREFIX || "", process.env.API_PREFIX || ""];

class PortalApi extends Api {
  async init(params: Parameters<Api["init"]>[0]) {
    await super.init(params);

    if (this.account.isAuth && !this.account.user) {
      await this.account.fetchCurrentUser();
    }
  }
}

export const apiPath = `${window.location.origin}/${stripUselessSlashes(...API_URL_PARTS)}/`;

export const api = new PortalApi({
  url: apiPath,
  urlPath: APP_CONFIG.URL_PATH,
  wsUrl: `${/https/.test(locationUrl.protocol) ? "wss://" : "ws://"}${locationUrl.host}/ws/`,
  httpOptions: {
    hooks: {
      afterResponse: [
        (response) => {
          if (LOGOUT_STATUS_CODES.includes(response.status) && PRIVATE_ROUTES.includes(window.location.pathname)) {
            api.account.logout();
          }

          return response;
        },
      ],
    },
  },
});
